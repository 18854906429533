.chat {
  @media screen and (max-width: 768px) {
    margin-top: 0;
    border-radius: 0px;
    padding: 20px 0 80px;
  }
}

.message {
  @media screen and (max-width: 768px) {
    max-width: 210px;
    padding: 15px;
  }
  @media screen and (max-width: 575px) {
    &::before {
      border-left: 10px solid #ffffff;
      border-bottom: 0px solid #ffffff;
      border-top: 10px solid transparent;
      border-right: 0px solid #fff;
    }
    &_bot {
      &::before {
        left: -9px;
      }
    }
    &_user {
      &::before {
        right: -9px;
        border-left: 10px solid #dbe5ff;
        border-bottom: 0px solid #dbe5ff;
        border-top: 10px solid transparent;
        border-right: 0px solid #dbe5ff;
      }
    }
    &_confirm {
      &::before {
        border-left: 10px solid #27AE60;
        border-bottom: 0px solid #27AE60;
        border-top: 10px solid transparent;
        border-right: 0px solid #27AE60;
      }
    }
  }
}

.form {
  @media screen and (max-width: 768px) {
    &_input,
    &_text {
      border: none;
      max-width: 210px;
      padding: 10px 10px 10px 15px;
    }
    &_input &,
    &_text & {
      &__input {
        height: 22px;
        width: 78%;
      }
      &__button {
        width: 20%;
        font-size: 12px;
        line-height: 15px;
        min-height: 22px;
        max-height: 24px;
      }
    }
    &_text & {
      &__input {
        height: initial;
      }
    }
    &_submit {
      max-width: 290px;
      padding: 10px;
    }
    &_submit & {
      &__input {
        margin-bottom: 10px;
        min-height: 30px;
      }
    }
  }
}
