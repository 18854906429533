@media screen and (max-width: 768px) {
  body {
    font-size: 12px;
    line-height: 15px;
  }
  .container {
    padding: 0 60px;
  }
  button {
    font-size: 12px;
    line-height: 15px;
  }
}

@media screen and (max-width: 575px) {
  .container {
    padding: 0 10px;
  }
}
