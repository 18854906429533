/**********/
/*************/
/* Defaults */
@import "common/_responsive";


/***********/
/* Blocks */
@import "chat/_responsive";
@import "header/_responsive";
@import "nav/_responsive";
@import "footer/_responsive";